// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-plants-for-business-js": () => import("./../../../src/pages/plants-for-business.js" /* webpackChunkName: "component---src-pages-plants-for-business-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-single-js": () => import("./../../../src/templates/blog-single.js" /* webpackChunkName: "component---src-templates-blog-single-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-plants-for-business-default-page-js": () => import("./../../../src/templates/plants-for-business/default-page.js" /* webpackChunkName: "component---src-templates-plants-for-business-default-page-js" */),
  "component---src-templates-plants-for-business-project-page-js": () => import("./../../../src/templates/plants-for-business/project-page.js" /* webpackChunkName: "component---src-templates-plants-for-business-project-page-js" */),
  "component---src-templates-plants-for-business-sector-page-js": () => import("./../../../src/templates/plants-for-business/sector-page.js" /* webpackChunkName: "component---src-templates-plants-for-business-sector-page-js" */),
  "component---src-templates-plants-for-business-service-page-js": () => import("./../../../src/templates/plants-for-business/service-page.js" /* webpackChunkName: "component---src-templates-plants-for-business-service-page-js" */),
  "component---src-templates-product-category-js": () => import("./../../../src/templates/product-category.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

