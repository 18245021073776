import React, { createContext, useState, useEffect } from 'react';
import Client from 'shopify-buy';

const client = Client.buildClient({
  domain: process.env.GATSBY_SHOPIFY_STORE_URL,
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
});

const defaultValues = {
  isOverlayActive: false,
  toggleOverlayActive: () => {},

  isSearchOpen: false,
  toggleSearchOpen: () => {},

  isCartOpen: false,
  toggleCartOpen: () => {},

  client,
  checkout: {
    lineItems: [],
    totalPrice: 0,
    webUrl: '',
  },
  addProductsToCheckout: () => {},
  updateProductInCheckout: () => {},
  removeProductFromCheckout: () => {},
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }) => {
  const [isOverlayActive, setOverlayActive] = useState(false);
  const toggleOverlayActive = () => setOverlayActive(!isOverlayActive);

  const [isSearchOpen, setSearchOpen] = useState(false);
  const toggleSearchOpen = () => {
    toggleOverlayActive(!isSearchOpen);
    setSearchOpen(!isSearchOpen);
  };

  const [isCartOpen, setCartOpen] = useState(false);
  const toggleCartOpen = () => {
    toggleOverlayActive(!isCartOpen);
    setCartOpen(!isCartOpen);
  };

  const [checkout, setCheckout] = useState(defaultValues.checkout);

  const isBrowser = typeof window !== 'undefined';
  const localStorageCheckoutIdKey = `${process.env.GATSBY_SHOPIFY_SHOP_NAME}:checkout-id`;

  useEffect(() => {
    initialiseCheckout();
  }, []);

  const initialiseCheckout = async () => {
    try {
      const currentCheckoutId = isBrowser
        ? localStorage.getItem(localStorageCheckoutIdKey)
        : null;
      let newCheckout;

      if (currentCheckoutId) {
        newCheckout = await client.checkout.fetch(currentCheckoutId);
        if (newCheckout.completedAt) {
          newCheckout = await getNewCheckout();
        }
      } else {
        newCheckout = await getNewCheckout();
      }

      setCheckout(newCheckout);
    } catch (e) {
      console.error(e);
      const newCheckout = await getNewCheckout();
      setCheckout(newCheckout);
    }
  };

  const getNewCheckout = async () => {
    try {
      const newCheckout = await client.checkout.create();

      if (isBrowser) {
        localStorage.setItem(localStorageCheckoutIdKey, newCheckout.id);
      }

      return newCheckout;
    } catch (e) {
      console.error(e);
    }
  };

  const addProductsToCheckout = async lineItems => {
    try {
      const newCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItems
      );

      setCheckout(newCheckout);
      toggleCartOpen();
    } catch (e) {
      console.error(e);
    }
  };

  const updateProductInCheckout = async (lineItemId, quantity) => {
    try {
      const newCheckout = await client.checkout.updateLineItems(checkout.id, [
        {
          id: lineItemId,
          quantity: quantity,
        },
      ]);

      setCheckout(newCheckout);
    } catch (e) {
      console.error(e);
    }
  };

  const removeProductFromCheckout = async lineItemId => {
    try {
      const newCheckout = await client.checkout.removeLineItems(checkout.id, [
        lineItemId,
      ]);

      setCheckout(newCheckout);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,

        isOverlayActive,
        toggleOverlayActive,

        isSearchOpen,
        toggleSearchOpen,

        isCartOpen,
        toggleCartOpen,

        checkout,
        addProductsToCheckout,
        updateProductInCheckout,
        removeProductFromCheckout,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
